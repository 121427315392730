<template>
  <div class="medical">
    <page-nav :page-list="pageList"></page-nav>

    <section id="header">
      <div class="context">
        <div class="title pd-btm-32">
          开源企业
        </div>
        <div class="describe">
          用开源创造更好的IT技术
        </div>
      </div>
    </section>
    <div class="container">
      <section id="sense-of-worth" class="pd-top-128">
        <div class="base-title pd-btm-32">
          我们的价值观
        </div>
        <ul class="sense-list">
          <li class="sense-item">
            <div class="image">
              <img src="/imgs/icon_empathy.png" alt="">
            </div>
            <span class="title">openness</span>
            <span class="dscribe">开源精神传承</span>
          </li>
          <li class="sense-item">
            <div class="image">
              <img src="/imgs/icon_new.png" alt="">
            </div>
            <span class="title">Produce</span>
            <span class="dscribe">不断的迭代新品</span>
          </li>
          <li class="sense-item">
            <div class="image">
              <img src="/imgs/icon_open.png" alt="">
            </div>
            <span class="title">Empathy</span>
            <span class="dscribe">共赢守护的动机</span>
          </li>
          <li class="sense-item">
            <div class="image">
              <img src="/imgs/icon_produce.png" alt="">
            </div>
            <span class="title">New</span>
            <span class="dscribe">不竭的创新</span>
          </li>
        </ul>
      </section>
      <section id="advantage" class="pd-top-128">
        <div class="base-title pd-btm-32">
          我们的优势
        </div>
        <ul class="advantage-list">
          <li class="advantage-item">
            <div class="image">
              <img src="/imgs/image_advantage01.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">便捷</span>
              <span class="text pd-btm-12">+Saas模式，无需硬件和部署成本</span>
              <span class="text pd-btm-12">+支持研究者随时调整研究指标</span>
              <span class="text pd-btm-12">+电脑端/微信端/PAD端全覆盖</span>
            </div>
          </li>
          <li class="advantage-item">
            <div class="image">
              <img src="/imgs/image_advantage02.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">安全</span>
              <span class="text pd-btm-12">+完备的多中心多角色权限体系</span>
              <span class="text pd-btm-12">+数据的提交和更改，均可溯源</span>
              <span class="text pd-btm-12">+除研究者外，其他人无权查看或使用数据 </span>
            </div>
          </li>
          <li class="advantage-item">
            <div class="image">
              <img src="/imgs/image_advantage03.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">专业</span>
              <span class="text pd-btm-12">+开放与专家共建40+种疾病数据集 </span>
              <span class="text pd-btm-12">+完善的eCRF，支持11种自定义题型</span>
              <span class="text pd-btm-12">+研究数据一人一行，可直接导出分析</span>
            </div>
          </li>
          <li class="advantage-item">
            <div class="image">
              <img src="/imgs/image_advantage04.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">高效</span>
              <span class="text pd-btm-12">+支持通过excel批量导入数据 </span>
              <span class="text pd-btm-12">+支持逻辑建题，值域校验和自动计算等 </span>
              <span class="text pd-btm-12">+数据录入进度，多端口实时同步和统计</span>
            </div>
          </li>
        </ul>
      </section>
      <section id="experience" class="pd-top-128">
        <div class="base-title pd-btm-32">
          我们的经历
        </div>
        <div class="time-line">
          <div class="context">
            <div class="context-item">
              <div class="text pd-btm-12">成都万全新致</div>
              <div class="text">加入新致软件BIS系统</div>
              <div class="time">2018.03</div>
              <img class="line" src="/imgs/image_line_v.png" alt="">
            </div>
            <div class="context-item flex-bottom">
              <div class="time">2019.03</div>
              <div class="text pd-btm-12">新致百果</div>
              <div class="text">推出HIS全系统</div>
              <img class="line-bottom" src="/imgs/image_line_v.png" alt="">

            </div>
            <div class="context-item">
              <div class="text pd-btm-12">新致医疗云</div>
              <div class="text">推出智慧医疗及产品</div>
              <div class="time">2021.01</div>
              <img class="line" src="/imgs/image_line_v.png" alt="">
            </div>
            <div class="context-item flex-bottom">
              <div class="time">2022.05</div>
              <div class="text pd-btm-12">新致武汉医卫</div>
              <div class="text">开源企业发行版春节版</div>
              <img class="line-bottom" src="/imgs/image_line_v.png" alt="">
            </div>
          </div>
        </div>
        <!-- <div class="experience-list"></div> -->
        <ul class="experience-list mr-btm-20">
          <li class="experience-item">
            <div class="image">
              <img src="/imgs/image_experience01.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">众多案例,放心使用</span>
              <span class="text pd-btm-12">发行版拥有完全自主产权，产品都经过BBA试验检验，且经过区块链确权</span>
            </div>
          </li>
          <li class="experience-item">
            <div class="image">
              <img src="/imgs/image_experience02.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">产品创新,一切思考从用户开始</span>
              <span class="text pd-btm-12">从真实需求出发，再探索如何满足不同的需求。最求以用户为本的产品设计，也追求给你带来“好体验”。</span>
            </div>
          </li>
          <li class="experience-item">
            <div class="image">
              <img src="/imgs/image_experience03.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">向5G进军,探索上云</span>
              <span class="text pd-btm-12">我们建立了“1+4”科研链条，相信更多的投入，可以普惠开源企业产品，实现医疗信息一体化。</span>
            </div>
          </li>
          <li class="experience-item">
            <div class="image">
              <img src="/imgs/image_experience04.jpg" alt="">
            </div>
            <div class="context">
              <span class="title pd-btm-12">勇担使命,创造生态位</span>
              <span class="text pd-btm-12">通过交流促进合作（信息价值），打通医软壁垒，实现接口统一（技术效益），减少重复开发（经济效益），打破信息孤岛（行业效益），最终打造出信息化底层操作系统（行业效益），实现十四五普惠医疗数字化（社会效益）。</span>
            </div>
          </li>
        </ul>
        <div class="experience-footer">
          <div class="text-top pd-btm-32">把好产品造出来、卖出去，这是企业最朴素的定义。除此之外，企业还应该有社会责任。</div>
          <div class="text-btm">王浩-创始人 | CEO</div>
        </div>
        <div class="footer">
          <a href="javascript:;">
            查看开源企业发行版>
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PageNav from "../components/pageNav";
export default {
  data() {
    return {
      pageList:[
        {name: '公司首页', page:'/index'},
        {name: '公司介绍', page: '/'},
        {name: '公司产品', page: ''},
        {name: '用户案例', page: ''},
        {name: '联系方式', page: ''}
      ],
    }
  },
  components: {
    PageNav
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/medical.scss";
</style>
